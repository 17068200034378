/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch } from 'redux';
import { createAction, handleActions, Action } from 'redux-actions';
import history from 'store/history';
import { ROUTE_PATHS } from 'routes';

import { useRedux } from 'util/hook/redux';
import {
	CartData,
	clearProductData,
	getProductQuantity,
	removeProductQuantityById,
	storeProductQuantity,
} from 'util/storeProductQuantity';
import { getEcUserByStorage, setItem, useLocalStorage } from 'util/storage';
import { FormObject, FormRowData } from 'util/hook/useFormBuilder';
import { api } from 'util/api';
import { V1UserOrderShoppingCartUpdateRequestPayload } from 'util/api/swaggerApi/data-contracts';

import { ConsigneeInfoProperty, InvoiceProperty } from 'components/molecules/CommonInfoModal';

import { IslandsType } from 'enums/islandsType';
import { RecipientType } from 'enums/recipientType';
import { ShoppingCartErrorType } from 'enums/shoppingCartErrorType';
import { DeliveryTimeType } from 'enums/deliveryTimeType';
import { InvoiceType } from 'enums/invoiceType';
import { CarrierType } from 'enums/carrierType';
import { PaymentType } from 'enums/paymentType';
import { OrderConfirmationType } from 'enums/orderConfirmationType';

import { ApiError } from 'types/apiError';
import { omniEvent } from 'util/omniEvent';
import { ProductData } from 'util/hook/useProducts';
import { formatTime } from 'util/formatTime';
import { gtmEcommerceEvent } from 'util/gtmEvent';

import { StorageKey } from 'enums/storageKey';
import pushHistory, { modifiedCurrentUrl } from 'util/pushHistory';
import { getCookie } from 'util/handleCookies';
import { checkErrorStatus } from './signin';
import { ModalTypes, openModal, openModalWithMessage } from './modal';
import { setSelectCoupon, setErrMessageEmpty } from './coupon';
import { GetState, State as GlobalState } from './reducers';
import { createInvoiceInfo, createRecipientInfo, updateUserInfo } from './member';
import { RedirectFnType, RedirectInfo, RedirectType } from './redirect';

const {
	v1UserEcpayInvoiceCheckMobileBarcodeList,
	v1UserOrderShoppingCartList,
	v1UserOrderShoppingCartUpdate,
	v1UserOrderCheckoutCreate,
	v1UserOrderPaymentCreate,
} = api;

interface OrderTempsResponse {
	items: {
		products: {
			id: string;
			quantity: string;
		}[];
		productCombinations: {
			id: string;
			quantity: string;
		}[];
		gifts: {
			id: string;
			quantity: string;
		}[];
	};
	order: {
		islandsType: string;
		shipType: string;
	};
	events: {
		couponEventId: number;
	};
}

export const setCartData = createAction(
	'SET_CART_DATA',
	(useApiData?: boolean) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			cart: { cartData },
			coupon: { userCouponList },
			member: { ecUserToken },
		} = getState();

		if ((!cartData || useApiData) && ecUserToken) {
			// useApiData:到step2頁面、從step2回step1會強制使用暫存訂單資料 (從其他頁面進入step1不會)
			try {
				const { data } = await v1UserOrderShoppingCartList();
				const cartDataFromApi: CartData = {
					normal: {},
					combination: {},
				};
				const giftDataFromApi: Record<string, number> = {};
				const {
					items: { products, productCombinations, gifts },
					order: { islandsType, shipType },
					events: { couponId },
				} = data?.data as unknown as OrderTempsResponse;

				products?.forEach(product => {
					if (product.id) {
						cartDataFromApi.normal[product.id] = Number(product.quantity);
						// useApiData時使用api暫存訂單之資料更新 reducer 跟 localStorage
						if (useApiData) {
							storeProductQuantity(Number(product.id), Number(product.quantity), false);
						}
					}
				});
				productCombinations?.forEach(product => {
					if (product.id) {
						cartDataFromApi.combination[product.id] = Number(product.quantity);
						if (useApiData) {
							storeProductQuantity(Number(product.id), Number(product.quantity), true);
						}
					}
				});

				gifts?.forEach(product => {
					if (product.id) {
						giftDataFromApi[product.id] = Number(product.quantity);
					}
				});

				const selectCoupon = userCouponList.find(coupon => coupon.id === couponId);

				if (selectCoupon) {
					dispatch(setSelectCoupon(selectCoupon));
				}
				if (islandsType && shipType) {
					dispatch(setDeliveryMethod({ location: islandsType, method: shipType }));
				}

				return {
					cartData: getProductQuantity() && !useApiData ? getProductQuantity() : cartDataFromApi,
					giftData: giftDataFromApi,
					apiData: cartDataFromApi,
				};
			} catch (e) {
				dispatch(checkErrorStatus(e as ApiError));
				return { cartData: getProductQuantity() };
			}
		}
		// 非第一次操作購物車頁面
		return { cartData: getProductQuantity() };
	},
);

export const removeCartProductById = createAction(
	'REMOVE_CART_PRODUCT_BY_ID',
	(productId: number, isCombination = false) =>
		(dispatch: Dispatch) => {
			removeProductQuantityById(productId, isCombination);
			dispatch(setCartData());
		},
);

export const storeCartProduct = createAction(
	'STORE_CART_PRODUCT_BY_ID',
	(productId: number, quantity: number, isCombination = false) =>
		(dispatch: Dispatch) => {
			storeProductQuantity(productId, quantity, isCombination);
			dispatch(setCartData());
		},
);

export const clearCart = createAction('CLEAR_CART', () => (dispatch: Dispatch) => {
	clearProductData();
	dispatch(setCartData());
});

export const selectInvoice = createAction('SELECT_INVOICE', (data: InvoiceProperty | null) => data);

export const selectRecipient = createAction(
	'SELECT_RECIPIENT',
	(data: ConsigneeInfoProperty | null) => data,
);

export const setDeliveryMethod = createAction('SET_DELIVERY_METHOD', (data: FormRowData) => data);

export const checkMobileBarcode = createAction('CHECK_MOBILE_BARCODE', async (num: string) => {
	try {
		const { data } = await v1UserEcpayInvoiceCheckMobileBarcodeList(
			{ carrier_no: num },
			{ secure: true },
		);
		return data?.data?.isExist === 'Y';
	} catch (e) {
		return false;
	}
});
export const setMobileBarcodeCheckUndefined = createAction('SET_MOBILE_BARCODE_CHECK_UNDEFINED');

export const handleEcPayMap = createAction(
	'HANDLE_ECPAY_MAP',
	async (CVSStoreID: string, CVSStoreName: string, CVSAddress: string) => {
		const { setValue, getValue } = useLocalStorage<FormObject>('orderForm');
		const localForm = getValue();
		if (localForm) {
			localForm.storeCode.value = CVSStoreID;
			localForm.storeName.value = CVSStoreName;
			localForm.storeAddress.value = CVSAddress;
			setValue(localForm);
		}
	},
);

export const sendShoppingCart = createAction(
	'SEND_SHOPPING_CART',
	(productsData: ProductData[], finalTotalPrice: number) =>
		async (dispatch: Dispatch, getState: GetState) => {
			const shoppingData: V1UserOrderShoppingCartUpdateRequestPayload = {
				order: {},
				items: {
					products: [],
					product_combinations: [],
				},
				frontend_paid_amount: finalTotalPrice,
			};
			const {
				cart: { deliveryMethod },
				coupon: { selectCoupon },
			} = getState();
			if (deliveryMethod) {
				shoppingData.order = {
					islands_type: deliveryMethod.location,
					ship_type: deliveryMethod.method,
				};
				if (getCookie('ecid')) {
					shoppingData.order.line_ecid = getCookie('ecid') || undefined;
				}
			}

			// normal
			productsData
				.filter(product => !product.isCombination)
				.forEach(product => {
					shoppingData?.items?.products?.push({ id: product.id, quantity: product.count });
				});
			// combination
			productsData
				.filter(product => product.isCombination)
				.forEach(product => {
					shoppingData?.items?.product_combinations?.push({
						id: product.id,
						quantity: product.count,
					});
				});

			gtmEcommerceEvent('begin_checkout', finalTotalPrice, productsData, {
				coupon: selectCoupon.couponEvent?.name || '',
			});

			if (selectCoupon) {
				shoppingData.coupon_id = selectCoupon.id;
			}
			try {
				await v1UserOrderShoppingCartUpdate(shoppingData);
				pushHistory(history, `/${ROUTE_PATHS.checkout}`);
				window.scrollTo(0, 0);
				return undefined;
			} catch (e) {
				const apiError = (e as ApiError).error;
				const errorMessage = apiError.message || '系統忙碌中，請稍後再試';
				if (apiError.errorCode === ShoppingCartErrorType.SERVICE_IS_MAINTAINING) {
					dispatch(openModal(ModalTypes.MaintainModal));
				} else {
					dispatch(openModalWithMessage(ModalTypes.ErrorModal, { title: errorMessage }));
				}
				return apiError.errorCode as ShoppingCartErrorType;
			}
		},
);

type CarrierTypeForCreate = 'NONE' | 'PHONE' | 'NATURAL_PERSON' | undefined;

export const sendOrderCheckout = createAction(
	'SEND_ORDER_CHECKOUT',
	(orderForm: Record<string, string>, shipping: number, productsData: ProductData[]) =>
		async (dispatch: Dispatch, getState: GetState): Promise<void | ShoppingCartErrorType> => {
			const {
				cart: { deliveryMethod },
				member: { userInfo },
				coupon: { selectCoupon },
			} = getState();

			const user = {
				name: orderForm.buyerName,
				mobile_number: orderForm.buyerMobile,
			};
			const ship_address =
				(orderForm?.city as string) +
					(orderForm?.area as string) +
					(orderForm?.address as string) || orderForm?.storeAddress;

			let carrier_no = '';
			if (orderForm?.saveMethod === CarrierType.NATURAL_PERSON) {
				carrier_no = orderForm?.certificate;
			} else if (orderForm?.saveMethod === CarrierType.PHONE) {
				carrier_no = orderForm?.mobileBarcode;
			}

			const order = {
				order_note: orderForm.orderRemark,
				recipient: orderForm.name,
				recipient_mobile: orderForm.mobile,
				recipient_phone: orderForm?.telephoneNumber,
				ship_address,
				expected_delivery_time: orderForm?.time as DeliveryTimeType,
				ship_note: orderForm?.shippingRemark,
				ship_type: deliveryMethod?.method,
				store_no: orderForm?.storeCode,
				store_name: orderForm?.storeName,
				invoice_type: orderForm.invoiceType as InvoiceType,
				carrier_type: orderForm?.saveMethod === '' ? CarrierType.NONE : orderForm?.saveMethod,
				carrier_no,
				love_code: orderForm?.donateUnit,
				tax_name: orderForm?.companyName,
				tax_num: orderForm?.taxId,
				payment_type: orderForm.payMethod as PaymentType,
			};

			try {
				const { data, status } = await v1UserOrderCheckoutCreate({ user, order });
				let orderId = -1;
				let orderNo = '';
				let totalPrice = 0;

				orderId = data?.data?.id;
				orderNo = data?.data?.orderNo;
				totalPrice = data?.data?.paidAmount;

				dispatch(
					createOrderPayment(orderForm.payMethod as PaymentType, orderId, orderNo, totalPrice),
				);
				dispatch(setSelectCoupon({}));
				dispatch(setErrMessageEmpty());

				if (status === 200) {
					omniEvent(null); // Clear the previous i13n object.
					omniEvent({
						action: 'Purchase',
						purchaseId: orderNo,
						affiliation: '愛康官網',
						uid: userInfo.id ?? '',
						revenue: totalPrice,
						shipping,
						coupon: selectCoupon.id || '',
						products: productsData.map(p => ({
							name: p.productName,
							id: p.materialNo,
							price: p.price,
							brand: '愛康生技',
							variant: p.briefDescription,
							quantity: p.count,
						})),
					});

					gtmEcommerceEvent('purchase', totalPrice, productsData, {
						shipping,
						transaction_id: orderNo,
						coupon: selectCoupon.couponEvent?.name || '',
					});

					gtmEcommerceEvent('add_payment_info', totalPrice, productsData, {
						payment_type: orderForm.payMethod,
						coupon: selectCoupon.couponEvent?.name || '',
					});

					gtmEcommerceEvent('add_shipping_info', totalPrice, productsData, {
						shipping_tier: deliveryMethod?.method || '',
						coupon: selectCoupon.couponEvent?.name || '',
					});
					// 新增會員資料
					if (orderForm.buyerName || orderForm.buyerMobile) {
						const buyerInfo = {
							name: orderForm?.buyerName ?? undefined,
							mobile_number: orderForm?.buyerMobile ?? undefined,
						};
						try {
							dispatch(updateUserInfo(buyerInfo));
						} catch (e) {
							console.log(e);
						}
					}

					// 新增常用收件人資料
					if (orderForm.saveConsignee && deliveryMethod?.method) {
						const consigneeInfo = {
							type: deliveryMethod?.method,
							name: orderForm.name,
							mobile_number: orderForm.mobile,
							telephone_number: orderForm?.telephoneNumber,
							city: orderForm?.city,
							city_zone: orderForm?.area,
							address: orderForm?.address,
							expected_delivery_time: orderForm?.time as DeliveryTimeType,
							store_code: orderForm?.storeCode,
							store_name: orderForm?.storeName,
							store_address: orderForm?.storeAddress,
						};
						try {
							dispatch(createRecipientInfo(consigneeInfo));
						} catch (e) {
							console.log(e);
						}
					}

					// 新增常用發票資料
					if (orderForm.saveInvoice) {
						const carrier_type = (
							orderForm?.saveMethod === CarrierType.DONATE ||
							orderForm?.saveMethod === CarrierType.MEMBER ||
							orderForm?.saveMethod === ''
								? CarrierType.NONE
								: orderForm?.saveMethod
						) as CarrierTypeForCreate;

						const invoiceInfo = {
							type: orderForm.invoiceType as InvoiceType,
							carrier_type,
							carrier_no:
								orderForm?.saveMethod === CarrierType.NATURAL_PERSON
									? orderForm?.certificate
									: orderForm?.mobileBarcode,
							tax_name: orderForm?.companyName,
							tax_num: orderForm?.taxId,
						};
						try {
							dispatch(createInvoiceInfo(invoiceInfo));
						} catch (e) {
							console.log(e);
						}
					}
				}
			} catch (e) {
				console.log(e);

				if (
					'error' in (e as ApiError) &&
					'errorCode' in (e as ApiError).error &&
					((e as ApiError).error.errorCode === ShoppingCartErrorType.LOCK_STOCKS_ARE_EXPIRED ||
						(e as ApiError).error.errorCode === ShoppingCartErrorType.SHIP_ADDRESS_IS_NOT_SUPPORT ||
						(e as ApiError).error.errorCode ===
							ShoppingCartErrorType.SHIP_TYPE_HOME_IS_NOT_SUPPORT ||
						(e as ApiError).error.errorCode === ShoppingCartErrorType.LINE_SHOP_ORDER_ERROR)
				) {
					dispatch(openModal(ModalTypes.ErrorModal));
					return (e as ApiError).error.errorCode as ShoppingCartErrorType;
				}
				dispatch(setConfirmationStatus(OrderConfirmationType.ORDER_FAIL));
				pushHistory(history, `/${ROUTE_PATHS.checkout}`);
				window.scrollTo(0, 0);
			}
		},
);

export const createOrderPayment = createAction(
	'CREATE_ORDER_PAYMENT',
	(payMethod: PaymentType, orderId: number, orderNo: string, totalPrice: number) =>
		async (dispatch: Dispatch): Promise<{ order?: Order; error?: ShoppingCartErrorType }> => {
			try {
				// const format =
				// 	payMethod === PaymentType.CATHAY_CREDIT_CARD
				// 		? ({ format: 'text' } as RequestParams)
				// 		: ({ format: 'json' } as RequestParams);

				const paymentData = await v1UserOrderPaymentCreate({
					order_id: orderId,
				}); // 此api回傳值需參考swagger

				if (payMethod === PaymentType.CATHAY_CREDIT_CARD || payMethod === PaymentType.LINE_PAY) {
					const redirectInfo: RedirectInfo<unknown> = {
						type:
							payMethod === PaymentType.CATHAY_CREDIT_CARD
								? RedirectType.CATHAY_CREDIT_CARD_PAY
								: RedirectType.LINE_PAY,
						featureType: RedirectFnType.ORDER_CONFIRMATION,
						backPath: `/${ROUTE_PATHS.checkout}`,
						otherParams: modifiedCurrentUrl().searchParams.toString(),
					};

					setItem(StorageKey.REDIRECT_INFO, JSON.stringify(redirectInfo));
				}

				const ecToken = getEcUserByStorage();

				switch (payMethod) {
					case PaymentType.CATHAY_ATM:
						dispatch(setConfirmationStatus(OrderConfirmationType.WAITING_FOR_TRANSFER));
						break;
					case PaymentType.HOME_CASH:
						dispatch(setConfirmationStatus(OrderConfirmationType.ORDER_ESTABLISHED));
						break;
					case PaymentType.CATHAY_CREDIT_CARD:
						// eslint-disable-next-line no-case-declarations
						// const winUrl = URL.createObjectURL(
						// 	new Blob([paymentData as string], { type: 'text/html' }),
						// );
						// 若是從付款頁面按上一頁，須從localstorage取得訂單資訊，並顯示付款失敗
						setItem('paypage', JSON.stringify({ id: orderId, no: orderNo, totalPrice, payMethod }));
						window.location.href = paymentData.view;
						break;
					case PaymentType.LINE_PAY:
						setItem(StorageKey.EC_USER_TEMP, ecToken);
						setItem('paypage', JSON.stringify({ id: orderId, no: orderNo, totalPrice, payMethod }));
						// 跳轉到指定頁面
						window.location.href = paymentData.data?.data?.data?.info?.paymentUrl?.web;
						break;
					default:
						break;
				}

				pushHistory(history, `/${ROUTE_PATHS.checkout}`);
				window.scrollTo(0, 0);
				dispatch(clearCart());

				return {
					order: {
						id: orderId,
						no: orderNo,
						virtualAccount: paymentData?.data?.data?.virtualAccount || '',
						expiryDate: formatTime(paymentData?.data?.data?.expiryDate) || '',
						totalPrice,
						payMethod,
					},
				};
			} catch (e) {
				console.log(e);

				window.scrollTo(0, 0);
				if (
					!('error' in (e as ApiError) && 'errorCode' in (e as ApiError).error) ||
					!Object.values(ShoppingCartErrorType).includes(
						(e as ApiError).error.errorCode as ShoppingCartErrorType,
					)
				) {
					dispatch(setConfirmationStatus(OrderConfirmationType.ORDER_FAIL));
					pushHistory(history, `/${ROUTE_PATHS.checkout}`);
				} else {
					const { errorCode } = (e as ApiError).error;
					dispatch(openModal(ModalTypes.ErrorModal));
					return { error: errorCode as ShoppingCartErrorType };
				}
			}
		},
);

export const setConfirmationStatus = createAction(
	'SET_CONFIRMATION_STATUS',
	(status: OrderConfirmationType | undefined) => status,
);

export const setOrderToDefault = createAction('SET_ORDER_TO_DEFAULT');

export type DeliveryMethod = { location: IslandsType; method: RecipientType };
// For Global State usage

export interface Order {
	id?: number;
	no: string;
	virtualAccount: string;
	expiryDate: string;
	totalPrice: number;
	payMethod: PaymentType;
}
export interface State {
	loading: boolean;
	cartData: CartData | null;
	giftData: Record<string, number> | null;
	invoice: InvoiceProperty | null;
	recipient: ConsigneeInfoProperty | null;
	deliveryMethod: DeliveryMethod | null;
	barcodeIsExist: boolean | undefined;
	sendShoppingCartError: ShoppingCartErrorType | undefined;
	apiData: CartData | null;
	confirmationStatus: OrderConfirmationType | undefined;
	order: Order;
	checkBarcodeLoading: boolean;
	paymentLoading: boolean;
}

export const defaultState: State = {
	loading: false,
	cartData: null,
	giftData: null,
	invoice: null,
	recipient: null,
	deliveryMethod: null,
	barcodeIsExist: undefined,
	sendShoppingCartError: undefined,
	apiData: null,
	confirmationStatus: undefined,
	order: {
		id: -1,
		no: '',
		virtualAccount: '',
		expiryDate: '',
		totalPrice: 0,
		payMethod: PaymentType.CATHAY_ATM,
	},
	checkBarcodeLoading: false,
	paymentLoading: false,
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	cart: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			SET_CART_DATA_FULFILLED: (
				state,
				action: Action<{
					cartData: CartData | null;
					giftData: Record<string, number> | null;
					deliveryMethod: DeliveryMethod | null;
					apiData: CartData | null;
				}>,
			) => ({
				...state,
				cartData: action.payload.cartData,
				giftData: action.payload.giftData ? action.payload.giftData : state.giftData,
				apiData: action.payload.apiData ? action.payload.apiData : state.apiData,
				loading: false,
			}),
			SELECT_INVOICE: (state, action: Action<InvoiceProperty>) => ({
				...state,
				invoice: action.payload,
			}),
			SELECT_RECIPIENT: (state, action: Action<ConsigneeInfoProperty>) => ({
				...state,
				recipient: action.payload,
			}),
			SET_DELIVERY_METHOD: (state, action: Action<DeliveryMethod>) => ({
				...state,
				deliveryMethod: action.payload,
			}),
			CHECK_MOBILE_BARCODE_PENDING: state => ({
				...state,
				checkBarcodeLoading: true,
			}),
			CHECK_MOBILE_BARCODE_FULFILLED: (state, action: Action<boolean>) => ({
				...state,
				barcodeIsExist: action.payload,
				checkBarcodeLoading: false,
			}),
			SET_MOBILE_BARCODE_CHECK_UNDEFINED: state => ({
				...state,
				barcodeIsExist: undefined,
			}),
			SEND_SHOPPING_CART_FULFILLED: (state, action: Action<ShoppingCartErrorType | undefined>) => ({
				...state,
				sendShoppingCartError: action.payload,
			}),
			CREATE_ORDER_PAYMENT_PENDING: state => ({
				...state,
				paymentLoading: true,
			}),
			CREATE_ORDER_PAYMENT_FULFILLED: (
				state,
				action: Action<{ order?: Order; error?: ShoppingCartErrorType }>,
			) => ({
				...state,
				order: action.payload?.order ? action.payload?.order : state.order,
				sendShoppingCartError: action.payload?.error ? action.payload.error : undefined,
				paymentLoading: false,
			}),
			SET_CONFIRMATION_STATUS: (state, action: Action<OrderConfirmationType>) => ({
				...state,
				confirmationStatus: action.payload,
			}),
			SEND_ORDER_CHECKOUT_FULFILLED: (state, action: Action<void | ShoppingCartErrorType>) => ({
				...state,
				sendShoppingCartError: action.payload ? action.payload : undefined,
			}),
			SET_ORDER_TO_DEFAULT: state => ({
				...state,
				order: {
					id: -1,
					no: '',
					virtualAccount: '',
					expiryDate: '',
					totalPrice: 0,
					payMethod: PaymentType.CATHAY_ATM,
				},
			}),
		},
		defaultState,
	),
};

const cartActionsMap = {
	setCartData,
	removeCartProductById,
	storeCartProduct,
	clearCart,
	selectInvoice,
	selectRecipient,
	setDeliveryMethod,
	checkMobileBarcode,
	handleEcPayMap,
	sendShoppingCart,
	sendOrderCheckout,
	createOrderPayment,
	setMobileBarcodeCheckUndefined,
	setConfirmationStatus,
	setOrderToDefault,
};

const mapHooksToState = (state: GlobalState) => ({
	cartData: state.cart.cartData,
	giftData: state.cart.giftData,
	invoice: state.cart.invoice,
	recipient: state.cart.recipient,
	deliveryMethod: state.cart.deliveryMethod,
	barcodeIsExist: state.cart.barcodeIsExist,
	sendShoppingCartError: state.cart.sendShoppingCartError,
	apiData: state.cart.apiData,
	confirmationStatus: state.cart.confirmationStatus,
	order: state.cart.order,
	checkBarcodeLoading: state.cart.checkBarcodeLoading,
	paymentLoading: state.cart.paymentLoading,
});

type CartSelector = ReturnType<typeof mapHooksToState>;
type CartActionsMap = typeof cartActionsMap;

export const useCart = () =>
	useRedux<CartSelector, CartActionsMap>(mapHooksToState, cartActionsMap);
